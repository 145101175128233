<template>
  <div>
    <section id="nosprefieren" class="mt-3 pref">
      <div class="container text-center">
        <h2 class="titles">
          Tu experiencia como usuario
          <span style=" font-style: normal !important;">ACD NÓMINA&reg;</span>
        </h2>
        <div class="row preferences">
          <div class="col-6 col-sm-4 text-center">
            <img
              src="@/assets/imagenes/1.svg"
              alt="licencia"
              width="40%"
              class="mt-3"
            />
            <h3>
              Sin licencias por uso y actualizaciones sin costo
            </h3>
          </div>
          <div class="col-6 col-sm-4 text-center">
            <img
              src="@/assets/imagenes/2.svg"
              alt="licencia"
              width="40%"
              class="mt-3"
            />
            <h3>
              Información accesible en todo momento
            </h3>
          </div>
          <div class="col-6 col-sm-4 text-center">
            <img
              src="@/assets/imagenes/3.svg"
              alt="licencia"
              width="40%"
              class="mt-3"
            />
            <h3>
              Implemetación y capacitación inmediata
            </h3>
          </div>
          <div class="col-6 col-sm-4 text-center">
            <br />
            <img
              src="@/assets/imagenes/4.svg"
              alt="licencia"
              width="40%"
              class="mt-3"
            />
            <h3>
              Asistencia legal y fiscal sin costo
            </h3>
          </div>
          <div class="col-6 col-sm-4 text-center">
            <br />
            <img
              src="@/assets/imagenes/5.svg"
              alt="licencia"
              width="40%"
              class="mt-3"
            />
            <h3>
              Seguridad en la nube
            </h3>
          </div>
          <div class="col-6 col-sm-4 text-center">
            <br />
            <img
              src="@/assets/imagenes/6.svg"
              alt="licencia"
              width="40%"
              class="mt-3"
            />
            <h3>
              Soporte técnico sin costo
            </h3>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "PreferencesComponent"
};
</script>
